import React from 'react';
import { Link } from 'gatsby';

const ValueProp = props => {
    return (
        <section className="value-prop">
            <div className="value-prop-content">
                <div className="main-message">
                    <h1>
                        Recibe tu botellón en 30 minutos <br className="break-line-dinamyc" />
                        <span id="value-dinamyc-text"></span>
                    </h1>
                    <p className="main-subtitle">
                        Evita la molestia de salir a comprar el botellón. Llegamos hasta la puerta
                        de tu domicilio, te llevamos el botellón lleno y nos das tu envase vacío.
                    </p>
                    <div className="value-prop-buttons">
                        <Link className="button" to="/descargar">
                            Quiero recargar mi botellón
                        </Link>
                    </div>
                </div>
                <div className="main-photo"></div>
            </div>
        </section>
    );
};

export default ValueProp;
