import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ValueProp from '../components/ValueProp';
import SocialProof from '../components/SocialProof';
import ProductBenefits from '../components/ProductBenefits';
import CallToAction from '../components/CallToAction';
import Newsletter from '../components/Newsletter';
import Testimonials from '../components/Testimonials';

const IndexPage = () => (
    <Layout>
        <SEO title="Aguaman | Te conectamos con el consumidor final" pathname="/" />
        <section className="value-prop">
            <div className="value-prop-content">
                <div className="main-message">
                    <h1>Conecta con clientes que consumen botellones de agua a domicilio</h1>
                    <p className="main-subtitle">
                        Ofrece a tus clientes el servicio de entrega de botellones de agua a
                        domicilio de forma fácil y conveniente. Nuestra app para clientes les
                        facilita realizar el pedido en cuestión de segundos.
                    </p>
                    <div className="value-prop-buttons">
                        <Link className="button" to="/descargar">
                            Quiero ver cómo funciona
                        </Link>
                    </div>
                </div>
                <div className="main-photo"></div>
            </div>
        </section>
        <section className="social-proof">
            <div className="social-proof-icons">
                <h2>¿Quieres conseguir más clientes y hacer crecer tu negocio?</h2>
                <h3 style={{ textAlign: 'center' }}>
                    Conectamos a los pequeños y grandes proveedores de botellones de agua con nuevos
                    clientes
                </h3>
            </div>
        </section>
        <section className="product-benefits">
            <div className="product-benefits-group">
                <div className="product-benefit-1-tagline">
                    <strong>Incrementa tu base de clientes</strong>{' '}
                    <p>
                        Los clientes de tu zona podrán hacer los pedidos en cuestión de segundos.
                        Nuestras estrategias de marketing conectarán su negocio con personas
                        interesadas en recibir botellones de agua a domicilio.
                    </p>
                </div>
                <div className="product-benefit-1-image"></div>
                <div className="product-benefit-2-tagline">
                    <strong>Gestiona los pedidos desde tu teléfono</strong>
                    <p>
                        Controla en tiempo real los pedidos y la información de tu cliente desde tu
                        teléfono celular. La plataforma te muestra la información detallada de cada
                        pedido para realizar entregas exitosas en cuestión de minutos.
                    </p>
                </div>
                <div className="product-benefit-2-image"></div>
                <div className="product-benefit-3-tagline">
                    <strong>Retiene a tus clientes y aumenta tus ingresos</strong>
                    <p>
                        Un cliente satisfecho siempre volverá a pedir y con la comodidad de que le
                        lleven el botellón a domicilio lo convertirás en un cliente fiel.
                    </p>
                </div>
                <div className="product-benefit-3-image"></div>
            </div>
        </section>
        <section className="testimonials">
            <h1>Planes</h1>
            <div className="testimonial-group">
                <div className="testimonial-card-1">
                    <div className="testimonial-1-message">
                        <h3 className="testimonial-2-name">Pequeñas plantas potabilizadoras</h3>
                        <p className="testimonial-1-title">0-100 botellones/mes</p>
                        <ul>
                            <li>Item 1</li>
                            <li>Item 2</li>
                        </ul>
                    </div>
                </div>
                <div className="testimonial-card-2">
                    <div className="testimonial-2-message">
                        <h3 className="testimonial-2-name">Distribuidoras</h3>
                        <p className="testimonial-2-title">100-500 botellones/mes</p>
                        <ul>
                            <li>Item 1</li>
                            <li>Item 2</li>
                        </ul>
                    </div>
                </div>
                <div className="testimonial-card-3">
                    <div className="testimonial-3-message">
                        <h3 className="testimonial-3-name">Embotelladoras industriales</h3>
                        <p className="testimonial-2-title">+500 botellones/mes</p>
                        <ul>
                            <li>Item 1</li>
                            <li>Item 2</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/* <Newsletter /> */}
        <section className="cta">
            <h1>¡Conéctate con nosotros y pon tu negocio en el mapa!</h1>
            <p>
                Imagínate poder ofrecer a tus clientes un servicio de entrega de agua a domicilio
                confiable y eficiente, con solo unos pocos clics. Nuestra plataforma es fácil de
                usar y te ayudará a alcanzar un público más amplio, incrementando directamente tus
                ingresos.
            </p>
            <Link className="button" to="/descargar">
                Quiero probar la plataforma
            </Link>
        </section>
    </Layout>
);

export default IndexPage;
