import React from 'react';
import { Link } from 'gatsby';

const Testimonials = props => {
    return (
        <section className="testimonials">
            <h1>Preguntas frecuentes </h1>
            <div className="testimonial-group">
                <div className="testimonial-card-1">
                    <div className="testimonial-1-message">
                        <h3 className="testimonial-2-name">¿Aceptan envases de otra marca?</h3>
                        <blockquote className="testimonial-1-content">
                            Sí. Siempre y cuando el botellón no esté partido, no debe tener
                            hundiduras ni tener malos olores internos (combustibles y otros olores
                            fuertes)
                        </blockquote>
                    </div>
                </div>
                <div className="testimonial-card-2">
                    <div className="testimonial-2-message">
                        <h3 className="testimonial-2-name">No tengo envase ¿Ustedes lo venden?</h3>
                        <blockquote className="testimonial-2-content">
                            Sí. El botellón nuevo viene con la primera recarga incluída y cada vez
                            que quieras recargar el botellón con nosotros, te llevaremos un envase
                            con las mismas condiciones.
                        </blockquote>
                    </div>
                </div>
                <div className="testimonial-card-3">
                    <div className="testimonial-3-message">
                        <h3 className="testimonial-3-name">¿De dónde proviene el agua?</h3>
                        <blockquote className="testimonial-3-content">
                            Es agua potable purificada bajo procesos de microfiltración,
                            ultrafiltración, rayos UV y ozono. Los botellones son lavados interna y
                            externamente antes de ser llenados.
                        </blockquote>
                    </div>
                </div>
            </div>
            <div className="testimonial-paragraph">
                <h3>
                    <Link
                        to="/preguntas-frecuentes"
                        style={{
                            color: 'white',
                            textDecoration: 'underline',
                        }}
                    >
                        Ver todas las preguntas frecuentes
                    </Link>
                </h3>
            </div>
        </section>
    );
};

export default Testimonials;
