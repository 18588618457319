import React, { useReducer } from 'react';
import { Link } from 'gatsby';
import Button from './Button';
import Loader from './Loader';

const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const isValidEmail = email => {
    if (email.match(re)) {
        return true;
    }
    return false;
};

const Newsletter = () => {
    const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
        email: null,
        error: null,
        loading: false,
        resolved: false,
    });

    const addEmailToList = e => {
        e.preventDefault();

        if (state.email && isValidEmail(state.email)) {
            setState({
                ...state,
                loading: true,
                error: null,
            });
            var myHeaders = new Headers();
            myHeaders.append(
                'Authorization',
                'Bearer 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',
            );
            myHeaders.append('Content-Type', 'application/json');
            const contactListId = 'b9914d4e-f671-4504-85af-cbf11b14028a';

            var raw = JSON.stringify({
                list_ids: [contactListId],
                contacts: [{ email: state.email }],
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            fetch('https://api.sendgrid.com/v3/marketing/contacts', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: true,
                        error: null,
                    });
                })
                .catch(error => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: false,
                        error,
                    });
                });
        } else {
            // show email error
            setState({
                ...state,
                error: 'Ingresa un correo electrónico válido',
            });
        }
    };

    return (
        <section className="newsletter-prop">
            <div className="newsletter-content">
                <div className="newsletter-main-message">
                    <h1>¿Vives en San Cristóbal y quieres probar el servicio?</h1>
                    <p className="newsletter-main-subtitle">
                        Recibe un cupón de 50% de descuento en tu primera recarga.
                    </p>
                    <form onSubmit={addEmailToList} className="newsletter-buttons">
                        <Link to="/primera-recarga" className="button button-success">
                            Quiero el cupón de descuento
                        </Link>
                    </form>
                    <div>
                        <p style={{ color: 'green' }}>
                            {state.resolved &&
                                'Revisa la bandeja de tu correo electrónico y canjea tu cupón de descuento.'}
                        </p>
                        {state.loading && <Loader />}
                        <p>{state.error}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Newsletter;
