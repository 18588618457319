import React from 'react';

const SocialProof = props => {
    return (
        <section className="social-proof">
            <div className="social-proof-icons">
                <h4>
                    Te ofrecemos la comodidad de recargar tu botellón sin tener que hacer ningún
                    esfuerzo, evitando las colas en los lugares de venta y con la garantía de tener
                    agua de calidad rápidamente.
                </h4>
            </div>
        </section>
    );
};

export default SocialProof;
