import React from 'react';
import { Link } from 'gatsby';

const CallToAction = props => {
    return (
        <section className="cta">
            <h2>Viniste por agua, te quedarás por el servicio.</h2>
            <p>
                Uno no sabe lo bueno que es recibir el botellón a domicilio hasta que conoces a
                aguaman.
            </p>
            <Link className="button" to="/descargar">
                Quiero probar el servicio
            </Link>
        </section>
    );
};

export default CallToAction;
